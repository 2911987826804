<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="profile-main-start box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper w-100">
        <div class="row">
          <div class="col-md-12">
            <div class="orders-tab-main ">
              <div class="center-card | d-flex flex-column position-relative">
                <div class="center-card-box | p-md-3">
                  <div class="flexone">
                    <b-tabs content-class="mt-3" lazy>
                      <b-tab :title="$t('My Profile')" active>
                        <update-profile />
                      </b-tab>
                      <b-tab :title="$t('Change Password')">
                        <change-password />
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>    
  </div>
</template>


<script>
import NavBar from "@/components/NavBar.vue";
import ChangePassword from '../components/profile/ChangePassword.vue';
import UpdateProfile from '../components/profile/UpdateProfile.vue';
import Footer from '../components/Footer.vue';

export default {
  components: { NavBar, ChangePassword, UpdateProfile, Footer },
};
</script>